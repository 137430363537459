import type { ComponentType } from 'react';
import InitLog from '@kiwicom/nitro/lib/components/InitLog';
import { Provider as LogProvider } from '@kiwicom/nitro/lib/services/log/context';
import { AuthProvider } from '@kiwicom/nitro/lib/services/auth/context';
import type { Brand } from '@kiwicom/nitro/lib/records/Brand';
import { IntlProvider } from '@kiwicom/nitro/lib/services/intl/context';
import type { IntlRaw } from '@kiwicom/nitro/lib/records/Intl';
import { Provider as BrandProvider } from '@kiwicom/nitro/lib/services/brand/context';
import { useRouter } from 'next/router';
import { CookiesProvider } from '@kiwicom/cookies';

type Props = {
  readonly brand: Brand;
  readonly children: JSX.Element;
  readonly intlRaw: IntlRaw;
};
// List of path names where we don't want user to `/[lang]/` redirect on sign out
const forbiddenRedirectPathNames = ['/[language]/user/invite-traveler/[token]/signed-in'];

const NitroProvider = ({ brand, children, intlRaw }: Props) => {
  const { pathname } = useRouter();
  const langId = intlRaw.language.id;

  const handleSignOut = () => {
    const id = langId ?? 'en';

    if (!forbiddenRedirectPathNames.includes(pathname)) {
      // Redirect to search after sign out
      window.location.href = `/${id}/`;
    }
  };

  return (
    <CookiesProvider>
      <BrandProvider value={brand}>
        <IntlProvider intl={intlRaw}>
          <InitLog>
            {(log) => (
              <LogProvider value={log}>
                <AuthProvider brand={brand} langInfo={intlRaw.language} onSignOut={handleSignOut}>
                  {children}
                </AuthProvider>
              </LogProvider>
            )}
          </InitLog>
        </IntlProvider>
      </BrandProvider>
    </CookiesProvider>
  );
};

export default NitroProvider as ComponentType<Props>;
