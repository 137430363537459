import type { Context } from 'react';
import { createContext, useMemo, useContext } from 'react';
import { checkContext } from '@kiwicom/account-client-utils';

export type UserLocation = {
  readonly isoCountryCode: string;
  readonly longitude: number;
  readonly latitude: number;
};
type State = {
  userLocation: UserLocation;
};
export type Props = {
  readonly children: JSX.Element | JSX.Element[];
  readonly userLocation: UserLocation;
};

const GeoLocationContext: Context<State | null> = createContext<State | null>(null);

const GeoLocationProvider = ({ children, userLocation }: Props): JSX.Element => {
  const state = useMemo(
    () => ({
      userLocation,
    }),
    [userLocation],
  );
  return <GeoLocationContext.Provider value={state}>{children}</GeoLocationContext.Provider>;
};

const useGeoLocation = (): State => {
  return checkContext(useContext(GeoLocationContext), 'GeoLocationProvider');
};

export { GeoLocationProvider, useGeoLocation };
