/* istanbul ignore file */
import { useEffect } from 'react';
import nextCookie from 'next-cookies';
import { remove as cookieRemove, load as cookieLoad, Cookie } from '@kiwicom/cookies';
import Router, { useRouter } from 'next/router';
import type { NextPageContext } from 'next';
import { isBrowser } from '@adeira/js';
import type { LangInfo } from '@kiwicom/nitro/lib/records/LangInfo';
import type { Brand } from '@kiwicom/nitro/lib/records/Brand';

const ACCOUNT_BETA = 'account_beta';

function getNextToken(ctx: NextPageContext) {
  if (ctx == null) {
    return '';
  }

  const tokens = nextCookie(ctx) ?? {};
  return tokens.ua_session_token;
}

const getLanguage = (ctx?: NextPageContext): string => {
  let language: string | null = '';

  if (isBrowser() === true) {
    language = cookieLoad(Cookie.LANGUAGE);
  } else {
    const cookies = nextCookie(ctx);
    language = cookies.preferred_language;
  }

  return language?.length > 0 ? language : 'en';
};

const normalizeQueryParam = (queryParam: string | string[]): string => {
  return Array.isArray(queryParam) ? queryParam.join('') : queryParam;
};

const getToken = (ctx?: NextPageContext): string | null | undefined =>
  isBrowser() === true ? cookieLoad(Cookie.UA_SESSION_TOKEN) : getNextToken(ctx);

const useRedirectToLogin = () => {
  const router = useRouter();
  const token = getToken();
  const language: string = getLanguage();
  const isAuthenticated = token != null;
  useEffect(() => {
    if (!isAuthenticated) {
      void router.push(`/${language}/user/login`);
    }
  }, [isAuthenticated, language, router]);
};

const tryRedirectToLogin = (
  res: NextPageContext['res'],
  req: NextPageContext['req'],
  language: string,
) => {
  // during static export res is empty object
  if (res != null && req != null) {
    const redirectUrl = req.url == null ? '' : `?redirectUrl=${encodeURIComponent(req.url)}`;
    // Delete the cookie
    res.setHeader(
      'Set-Cookie',
      `${'ua_session_token'}=''; Expires=${new Date().toUTCString()}; Path=/`,
    );
    res.writeHead(302, {
      Location: `/${language}/user/login${redirectUrl}`,
    });
    res.end();
  } else if (process.browser) {
    const redirectUrl =
      Router.asPath == null ? '' : `?redirectUrl=${encodeURIComponent(Router.asPath)}`;
    cookieRemove(Cookie.UA_SESSION_TOKEN);
    void Router.push(`/${language}/user/login${redirectUrl}`);
  }
};

const BASE_FONT_STACK =
  "-apple-system, '.SFNSText-Regular', 'San Francisco', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande', sans-serif";

const DEFAULT_FONT_FAMILY_CSS = `'Circular Pro', ${BASE_FONT_STACK}`;

// This piece of logic is duplicated in @kiwicom/nitro/lib/records/Theme, but it is only available
// in later Nitro versions. We could clean this up once we upgrade.
//
const shouldLoadCircularProFont = (brandDomain: Brand['domain']) =>
  brandDomain === 'kiwi.com' || brandDomain.endsWith('.kiwi.com');

const getFontFamily = ({
  language,
  brandDomain,
}: {
  language: LangInfo;
  brandDomain: Brand['domain'];
}): string =>
  language.specialFont
    ? `'${language.specialFont}', ${
        shouldLoadCircularProFont(brandDomain) ? DEFAULT_FONT_FAMILY_CSS : BASE_FONT_STACK
      }`
    : `${shouldLoadCircularProFont(brandDomain) ? DEFAULT_FONT_FAMILY_CSS : BASE_FONT_STACK}`;

export {
  getFontFamily,
  getLanguage,
  getToken,
  tryRedirectToLogin,
  ACCOUNT_BETA,
  useRedirectToLogin,
  normalizeQueryParam,
};
