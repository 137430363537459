/* istanbul ignore file */
type DomainType = 'production' | 'staging' | 'preprod' | null;

const getDomainType = (url: string): DomainType => {
  // Possibilities
  // production: https://kiwi.com/en/*
  // preprod: https://preprod.kiwi.com/en/*
  // staging: https://account-branchname-review-app.fe-cloudrun.kiwi.com/*
  if (/https:\/\/kiwi.com/.exec(url) != null) {
    return 'production';
  } else if (/https:\/\/preprod.kiwi.com/.exec(url) != null) {
    return 'preprod';
  } else if (/https:\/\/account-[a-zA-Z0-9_-]*-review-app.fe-cloudrun.kiwi.com/.exec(url) != null) {
    return 'staging';
  }

  return null;
};

export default getDomainType;
