import { commonFields } from '../../utils/defaultValues';
import type { EventList } from '../../types';

export const privacySecurity: EventList = {
  PRIVACY_SECURITY_PAGE_LOADED: { ...commonFields, category: 'privacy security', action: 'loaded' },
  REVOKE_ACTIVE_SESSION_SUCCESS: {
    ...commonFields,
    category: 'privacy security',
    action: 'revoke active session success',
  },
  DELETE_ACCOUNT_REQUESTED: {
    ...commonFields,
    category: 'privacy security',
    action: 'account deletion requested',
  },
  DELETE_ACCOUNT_SUCCESS: {
    ...commonFields,
    category: 'privacy security',
    action: 'delete account success',
  },
  DELETE_ACCOUNT_ERROR: {
    ...commonFields,
    category: 'privacy security',
    action: 'deleted account fail',
  },
  DELETE_ACCOUNT_CANCELED: {
    ...commonFields,
    category: 'privacy security',
    action: 'deleted account canceled',
  },
  DELETE_ACCOUNT_MODAL_OPENED: {
    ...commonFields,
    category: 'privacy security',
    action: 'deleted account modal opened',
  },
  OPEN_SET_PASSWORD_MODAL: {
    ...commonFields,
    category: 'privacy security',
    action: 'set password modal opened',
  },
  CLOSE_SET_PASSWORD_MODAL: {
    ...commonFields,
    category: 'privacy security',
    action: 'set password modal closed',
  },
  SET_PASSWORD_SUCCESS: {
    ...commonFields,
    category: 'privacy security',
    action: 'set password was successful',
  },
  SET_PASSWORD_ERROR: {
    ...commonFields,
    category: 'privacy security',
    action: 'set password was not successful',
  },
  OPEN_CHANGE_PASSWORD_MODAL: {
    ...commonFields,
    category: 'privacy security',
    action: 'change password modal opened',
  },
  CLOSE_CHANGE_PASSWORD_MODAL: {
    ...commonFields,
    category: 'privacy security',
    action: 'change password modal closed',
  },
  CHANGE_PASSWORD_SUCCESS: {
    ...commonFields,
    category: 'privacy security',
    action: 'change password was successful',
  },
  CHANGE_PASSWORD_ERROR: {
    ...commonFields,
    category: 'privacy security',
    action: 'change password was not successful',
  },
  CHANGE_PASSWORD_WRONG_OLD_PASSWORD: {
    ...commonFields,
    category: 'privacy security',
    action: 'change password was not successful, wrong old password',
  },
};
