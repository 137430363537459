import { commonFields } from '../../utils/defaultValues';
import type { EventList } from '../../types';

export const messagesHistory: EventList = {
  SUBPAGE_OPENED: { ...commonFields, category: 'messages history', action: 'message subpage open' },
  SUBPAGE_CTA_CLICK: {
    ...commonFields,
    category: 'messages history',
    action: 'message subpage content cta button click',
  },
  SUBPAGE_PROMO_CODE_CLICK: {
    ...commonFields,
    category: 'messages history',
    action: 'message subpage content promo code click',
  },
  NOTIFICATION_ITEM_CLICK: {
    ...commonFields,
    category: 'messages history',
    action: 'message click',
  },
  NOTIFICATION_READ: { ...commonFields, category: 'messages history', action: 'message read' },
  GO_TO_HELP_EMPTY_MESSAGES: {
    ...commonFields,
    category: 'messages history',
    action: 'empty messages cta button click',
  },
  GO_TO_HELP: {
    ...commonFields,
    category: 'messages history',
    action: 'help shortcut cta button click',
  },
  TAB_OPEN: { ...commonFields, category: 'messages history', action: 'section open' },
};
