import { commonFields } from '../../utils/defaultValues';
import type { EventList } from '../../types';

export const preferences: EventList = {
  BILLING_ADDRESSES_CLICKED: {
    ...commonFields,
    category: 'preferences',
    action: 'billing addresses clicked',
  },
  SETTINGS_CLICKED: { ...commonFields, category: 'preferences', action: 'settings clicked' },
  SAVED_TRAVELLERS_CLICKED: {
    ...commonFields,
    category: 'preferences',
    action: 'saved travellers clicked',
  },
  ACCOUNT_SETTINGS_CLICKED: {
    ...commonFields,
    category: 'preferences',
    action: 'account settings clicked',
  },
  PRIVACY_SECURITY_CLICKED: {
    ...commonFields,
    category: 'preferences',
    action: 'privacy & security clicked',
  },
  PAYMENT_METHODS_CLICKED: {
    ...commonFields,
    category: 'preferences',
    action: 'payment methods clicked',
  },
  FLIGHT_ANCILLARIES_CLICKED: {
    ...commonFields,
    category: 'preferences',
    action: 'flight ancillaries clicked',
  },
};
