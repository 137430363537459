import { commonFields } from '../../utils/defaultValues';
import type { EventList } from '../../types';

export const accountSettings: EventList = {
  ACCOUNT_SETTINGS_PAGE_LOADED: { ...commonFields, category: 'accountSettings', action: 'loaded' },
  PROFILE_PICTURE_ADDED: {
    ...commonFields,
    category: 'accountSettings',
    action: 'added profile picture',
  },
  PROFILE_PICTURE_DELETED: {
    ...commonFields,
    category: 'accountSettings',
    action: 'deleted profile picture',
  },
  CURRENCY_CHANGE: {
    ...commonFields,
    category: 'account settings',
    action: 'currency change',
  },
  LANGUAGE_CHANGE: {
    ...commonFields,
    category: 'account settings',
    action: 'language change',
  },
};
