import { commonFields } from '../../utils/defaultValues';
import type { EventList } from '../../types';

export const priceAlerts: EventList = {
  PRICEALERT_LIST_DISPLAYED: {
    ...commonFields,
    category: 'price alerts',
    action: 'list displayed',
  },
  PRICEALERT_DETAIL_DISPLAYED: {
    ...commonFields,
    category: 'price alerts',
    action: 'detail displayed',
  },
  PRICEALERT_CLICK_SEARCH: {
    ...commonFields,
    category: 'price alerts',
    action: 'click on search results',
  },
  PRICEALERT_SELECT_RESULT: {
    ...commonFields,
    category: 'price alerts',
    action: 'click on select result',
  },
  PRICEALERT_CLICK_DEAL: {
    ...commonFields,
    category: 'price alerts',
    action: 'click on deal',
  },
  PRICEALERT_DELETE_BUTTON_CLICK: {
    ...commonFields,
    category: 'price alerts',
    action: 'delete button clicked',
  },
  PRICEALERT_DELETED: { ...commonFields, category: 'price alerts', action: 'price alert deleted' },
  START_EXPLORING: { ...commonFields, category: 'price alerts', action: 'start exploring' },
};
