import type { Props as SSRDataProviderProps } from './SSRContext';
import { SSRDataProvider } from './SSRContext';
import type { MediaContextProviderProps } from './Media/Media';
import { MediaContextProvider } from './Media/Media';
import type { Props as GeoLocationProps } from './GeoLocationContext';
import { GeoLocationProvider } from './GeoLocationContext';

type RootProviderProps<T> = Omit<T, 'children'>;

type Props = {
  readonly children: JSX.Element;
  readonly ssrData: RootProviderProps<SSRDataProviderProps>;
  readonly media?: RootProviderProps<MediaContextProviderProps>;
  readonly geolocation: RootProviderProps<GeoLocationProps>;
};

const RootProvider = (props: Props): JSX.Element => {
  const { media, ssrData, geolocation, children } = props;
  return (
    <MediaContextProvider {...media}>
      <SSRDataProvider {...ssrData}>
        <GeoLocationProvider {...geolocation}>{children}</GeoLocationProvider>
      </SSRDataProvider>
    </MediaContextProvider>
  );
};

export default RootProvider;
