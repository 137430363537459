export const PROMO_AREA_CONTENT = Object.freeze({
  TRAVEL_DOCUMENT_REQUIRED: 'TRAVEL_DOCUMENT_REQUIRED',
  REFER_FRIEND: 'REFER_FRIEND',
  FLIGHT_ANCILLARIES_PREFERENCES: 'FLIGHT_ANCILLARIES',
  APP_PROMOTION: 'APP_PROMOTION',
  CREDITS_EXPIRATION: 'CREDITS_EXPIRATION',
  NOTIFICATIONS_SETTINGS: 'NOTIFICATIONS_SETTINGS',
  ADD_TRAVELER: 'ADD_TRAVELER',
  REGIONAL_SETTINGS: 'REGIONAL_SETTINGS',
  TRAVEL_DOCUMENT_EXPIRED: 'TRAVEL_DOCUMENT_EXPIRED',
  AUTO_CHECK_IN: 'AUTO_CHECK_IN',
});
