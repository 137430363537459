import { homepage, promotionArea, ancillary, bookingSharing } from './myTrips';
import { priceAlerts } from './priceAlerts/priceAlerts';
import { fareLock } from './fareLock/fareLock';
import { kiwiCredit } from './kiwiCredits/kiwiCredit';
import { referFriend } from './referFriend/referFriend';
import { inbox, messagesHistory } from './inbox';
import { generalEvents, darwinEvents, onboarding, userBar, webVitals } from './general';
import {
  accountSettings,
  billingAddress,
  notificationsSettings,
  paymentMethods,
  preferences,
  privacySecurity,
  savedTravellers,
  inviteTraveler,
  flightPreferences,
} from './preferences';
import { accountLogin, magicLogin, setPassword, verifyEmail, resetPassword } from './loginEvents';
import { wallet } from './wallet/wallet';
import { VIP } from './vip/vip';
import { sfaq } from './sfaqEvents/sfaq';
import type { EventObjectCategory, EventList } from '../types';

const events: Record<EventObjectCategory, EventList> = {
  accountSettings,
  ancillary,
  billingAddress,
  bookingSharing,
  darwinEvents,
  homepage,
  inbox,
  promotionArea,
  kiwiCredit,
  paymentMethods,
  preferences,
  priceAlerts,
  savedTravellers,
  notificationsSettings,
  onboarding,
  inviteTraveler,
  accountLogin,
  magicLogin,
  setPassword,
  verifyEmail,
  resetPassword,
  messagesHistory,
  generalEvents,
  referFriend,
  fareLock,
  privacySecurity,
  sfaq,
  userBar,
  VIP,
  wallet,
  webVitals,
  flightPreferences,
};

export default events;
