import { commonFields } from '../../utils/defaultValues';
import type { EventList } from '../../types';

export const fareLock: EventList = {
  // Loaded section of active fare locks with at least one FL
  ACTIVE_FARE_LOCKS_LOADED: {
    ...commonFields,
    category: 'fare lock',
    action: 'active fare locks section loaded',
  },
  // Loaded section of expired & refunded fare locks with at least one FL
  EXPIRED_FARE_LOCKS_LOADED: {
    ...commonFields,
    category: 'fare lock',
    action: 'expired fare locks section loaded',
  },
  // Number of using pagination - clicks on “Show more” in active FL section
  ACTIVE_FARE_LOCKS_SHOW_MORE_CLICKED: {
    ...commonFields,
    category: 'fare lock',
    action: 'clicked on show more in active fare lock section',
  },
  // Number of using pagination - clicks on “Show more” in expired & refunded FL section
  EXPIRED_FARE_LOCKS_SHOW_MORE_CLICKED: {
    ...commonFields,
    category: 'fare lock',
    action: 'clicked on show more in expired fare lock section',
  },
  // Number of click on "Book now" button place in Active FL card
  BOOK_NOW_CLICKED: {
    ...commonFields,
    category: 'fare lock',
    action: 'clicked on book now from active fare lock',
  },
  // Number of opened expired FL modals
  OPENED_EXPIRED_FARE_LOCK_MODAL: {
    ...commonFields,
    category: 'fare lock',
    action: 'opened expired fare lock modal',
  },
  // Number of opened refunded FL modals
  OPENED_REFUNDED_FARE_LOCK_MODAL: {
    ...commonFields,
    category: 'fare lock',
    action: 'opened refunded fare lock modal',
  },
  // Number of clicks on "find new flights" in FL Modal
  FIND_FLIGHT_CLICKED_IN_MODAL: {
    ...commonFields,
    category: 'fare lock',
    action: 'clicked on find new flight in fare lock modal',
  },
  // Number of clicks on "continue to booking" in FL Modal
  TO_BOOKING_CLICKED_IN_MODAL: {
    ...commonFields,
    category: 'fare lock',
    action: 'clicked on continue to booking in fare lock modal',
  },
  // Number of clicks on CTA - "Start exploring" in empty FL component
  EMPTY_FARE_LOCK_CTA_CLICKED: {
    ...commonFields,
    category: 'fare lock',
    action: 'clicked on start exploring in empty fare lock screen',
  },
};
