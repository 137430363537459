const LocaleMap: Record<string, string> = {
  bg: 'bg',
  ct: 'es',
  cz: 'cs',
  da: 'da',
  de: 'de',
  at: 'de',
  ch: 'de',
  el: 'el',
  en: 'en-GB',
  au: 'en-GB',
  ca: 'en-GB',
  ee: 'en-GB',
  hk: 'en-GB',
  in: 'en-GB',
  ie: 'en-GB',
  my: 'ms',
  nz: 'en-GB',
  sg: 'en-GB',
  za: 'en-GB',
  ph: 'en-GB',
  us: 'en-US',
  es: 'es',
  ag: 'es',
  cl: 'es',
  co: 'es',
  ec: 'es',
  pe: 'es',
  mx: 'es',
  fr: 'fr',
  be: 'fr',
  'ca-fr': 'fr',
  hr: 'en-GB',
  id: 'id',
  is: 'en-GB',
  it: 'it',
  ja: 'ja',
  lt: 'lt',
  il: 'he',
  hu: 'hu',
  nl: 'nl',
  no: 'nb',
  pl: 'pl',
  pt: 'pt',
  br: 'pt-BR',
  ro: 'ro',
  ru: 'ru',
  by: 'ru',
  kz: 'ru',
  sk: 'sk',
  sr: 'sr',
  fi: 'fi',
  sv: 'sv',
  th: 'th',
  tr: 'tr',
  vn: 'vi',
  uk: 'uk',
  ar: 'ar-DZ',
  bh: 'ar-DZ',
  jo: 'ar-DZ',
  kw: 'ar-DZ',
  om: 'ar-DZ',
  qa: 'ar-DZ',
  ae: 'ar-DZ',
  ko: 'ko',
  cn: 'zh-CN',
  tw: 'zh-TW',
};
export default LocaleMap;
