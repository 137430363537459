/* istanbul ignore file */
import { invariant } from '@adeira/js';

export default function checkContext<T>(context: T | undefined, providerName: string): T {
  invariant(
    context !== undefined,
    `Expected to have a provider, but did not, did you forget the ${providerName}?`,
  );

  return context;
}
