import { commonFields } from '../utils/defaultValues';
import type { EventList } from '../types';

export const generalEvents: EventList = {
  DARWIN_FETCH_FAILED: {
    ...commonFields,
    category: 'generalEvents',
    action: 'Darwin config fetch failed',
  },
};

export const darwinEvents: EventList = {
  DARWIN_TEST_STARTED: {
    ...commonFields,
    category: 'darwin',
    action: 'test started',
  },
};

export const userBar: EventList = {
  SETTINGS_BUTTON_CLICKED: {
    ...commonFields,
    category: 'userBar',
    action: 'settings button clicked',
  },
};

export const onboarding: EventList = {
  CLOSE_MODAL: { ...commonFields, category: 'onboarding', action: 'Modal closed' },
  FINISH: { ...commonFields, category: 'onboarding', action: 'Onboarding finished' },
  HOME_LOCATIONS_SAVED: { ...commonFields, category: 'onboarding', action: 'Home locations saved' },
  // we will send also the current step ie the skipped step in the payload with this event
  STEP_SKIPPED: { ...commonFields, category: 'onboarding', action: 'Step skipped' },
  NOTIFICATIONS_ENABLED: {
    ...commonFields,
    category: 'onboarding',
    action: 'Notifications enabled',
  },
  NOTIFICATIONS_ENABLED_EXPONEA: {
    destinations: { exponea: true },
    category: 'exponea',
    action: 'newsletter',
  },
  NOTIFICATIONS_NOT_ENABLED: {
    ...commonFields,
    category: 'onboarding',
    action: 'Notifications not enabled',
  },
  PREFERRED_TRIPS_SAVED: {
    ...commonFields,
    category: 'onboarding',
    action: 'Preferred trips saved',
  },
  NOTIFICATION_SETTINGS_FETCH_FAILED: {
    ...commonFields,
    category: 'onboarding',
    action: 'Notifications settings fetch failed',
  },
  ONBOARDING_OPENED: {
    ...commonFields,
    category: 'onboarding',
    action: 'Onboarding opened',
  },
  SUCCESS_SCREEN_CTA: {
    ...commonFields,
    category: 'onboarding',
    action: 'Clicked on success screen cta',
  },
  INSTALL_IOS: {
    ...commonFields,
    category: 'onboarding',
    action: 'clicked on install ios app',
  },
  INSTALL_ANDROID: {
    ...commonFields,
    category: 'onboarding',
    action: 'clicked on install android app',
  },
  SEND_APP_SMS_LINK: {
    ...commonFields,
    category: 'onboarding',
    action: 'clicked on request app installation link',
  },
  SEND_APP_SMS_LINK_SUCCESS: {
    ...commonFields,
    category: 'onboarding',
    action: 'sms link sent successfully',
  },
  SEND_APP_SMS_LINK_ERROR: {
    ...commonFields,
    category: 'onboarding',
    action: 'sms link error',
  },
};

export const webVitals: EventList = {
  METRICS: {
    ...commonFields,
    category: 'webVitals',
    action: 'web vitals reported',
    destinations: { exponea: false, ga: false, logmole: false, datadog: true, bigQuery: false },
  },
};
