import type {
  Environment,
  OperationLoader,
  SubscribeFunction,
  GraphQLResponse,
} from 'relay-runtime';
import { Network, Environment as RelayEnvironment } from 'relay-runtime';
import type { RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes';

import createRelayStore from './createRelayStore';
import createRequestHandler from './createRequestHandler';

type Options = {
  readonly fetchFn: (...args: readonly unknown[]) => Promise<GraphQLResponse>;
  readonly subscribeFn?: SubscribeFunction;
  readonly handlerProvider?: (arg0: string) => void;
  readonly operationLoader?: OperationLoader;
  readonly records?: RecordMap | null | undefined;
  readonly gcReleaseBufferSize?: number | null | undefined;
};

function createNetwork(
  fetchFn: (...args: readonly unknown[]) => Promise<GraphQLResponse>,
  subscribeFn?: SubscribeFunction,
) {
  const fetch = createRequestHandler(fetchFn);
  return Network.create(fetch, subscribeFn);
}

export default function createEnvironment(options: Options): Environment {
  const { fetchFn, subscribeFn, records, gcReleaseBufferSize, ...rest } = options;
  return new RelayEnvironment({
    network: createNetwork(fetchFn, subscribeFn),
    store: createRelayStore(records, {
      gcReleaseBufferSize,
    }),
    ...rest,
  });
}
