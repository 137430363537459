import type { EventList } from '../../types';
import { commonFields } from '../../utils/defaultValues';

export const promotionArea: EventList = {
  // CTA of promotion area content type TRAVEL_DOCUMENT_REQUIRED
  CLICK_BOOKING_DETAILS: {
    ...commonFields,
    category: 'homepage',
    action: 'visit booking details from promotion area',
  },
  // CTA of promotion area content type CREDITS_EXPIRATION
  CLICK_SEARCH: {
    ...commonFields,
    category: 'homepage',
    action: 'visit search from promotion area when credits are nearly expired',
  },
  // CTA of promotion area content type NOTIFICATIONS_SETTINGS
  CLICK_NOTIFICATIONS_SETTINGS: {
    ...commonFields,
    category: 'homepage',
    action: 'visit notifications settings from promotion area',
  },
  // CTA of promotion area content type ADD_TRAVELER
  CLICK_ADD_TRAVELER: {
    ...commonFields,
    category: 'homepage',
    action: 'visit travelers tab from promotion area',
  },
  // CTA of promotion area content type REGIONAL_SETTINGS
  CLICK_REGIONAL_SETTINGS: {
    ...commonFields,
    category: 'homepage',
    action: 'open regional settings modal in settings tab from promotion area',
  },
  // CTA of promotion area content type APP_PROMOTION
  CLICK_OPEN_MOBILE_APP_STORE: {
    ...commonFields,
    category: 'homepage',
    action: 'open kiwi.com app page in mobile app store',
  },
  // CTA of promotion area content type APP_PROMOTION
  CLICK_OPEN_FLIGHT_ANCILLARIES_PREFERENCES: {
    ...commonFields,
    category: 'homepage',
    action: 'open Flight ancillaries preferences tab in account',
  },
  CARD_FLIGHT_ANCILLARIES_PREFERENCES_SHOWN: {
    ...commonFields,
    category: 'homepage',
    action: 'Flight ancillaries preferences card shown in promotion area',
  },
  CLICK_OPEN_REFER_FRIEND: {
    ...commonFields,
    category: 'homepage',
    action: 'open Refer a friend tab in account',
  },
  CARD_TRAVEL_DOCUMENT_REQUIRED_SHOWN: {
    ...commonFields,
    category: 'homepage',
    action: 'Travel document required card shown in promotion area',
  },
  CARD_REFER_FRIEND_SHOWN: {
    ...commonFields,
    category: 'homepage',
    action: 'Refer a friend card shown in promotion area',
  },
  CARD_APP_PROMOTION_SHOWN: {
    ...commonFields,
    category: 'homepage',
    action: 'App promotion card shown in promotion area',
  },
  CARD_CREDITS_EXPIRATION_SHOWN: {
    ...commonFields,
    category: 'homepage',
    action: 'Credits expiration card shown in promotion area',
  },
  CARD_NOTIFICATIONS_SETTINGS_SHOWN: {
    ...commonFields,
    category: 'homepage',
    action: 'Notification settings card shown in promotion area',
  },
  CARD_ADD_TRAVELER_SHOWN: {
    ...commonFields,
    category: 'homepage',
    action: 'Add traveler card shown in promotion area',
  },
  CARD_REGIONAL_SETTINGS_SHOWN: {
    ...commonFields,
    category: 'homepage',
    action: 'Regional settings card shown in promotion area',
  },
  CARD_AUTO_CHECK_IN_SHOWN: {
    ...commonFields,
    category: 'homepage',
    action: 'Auto check-in card shown in promotion area',
  },
  // CTA of AutoCheckInContent
  CLICK_AUTO_CHECK_IN: {
    ...commonFields,
    category: 'homepage',
    action: 'open edit account owner modal from Auto check-in card',
  },
  CARD_TRAVEL_DOCUMENT_EXPIRED_SHOWN: {
    ...commonFields,
    category: 'homepage',
    action: 'Travel document expired card shown in promotion area',
  },
  CLICK_TRAVEL_DOCUMENT_EXPIRED: {
    ...commonFields,
    category: 'homepage',
    action: 'open edit account owner modal from Travel document expiredcard',
  },
};
