import { commonFields } from '../../utils/defaultValues';
import type { EventList } from '../../types';

export const VIP: EventList = {
  HOME_LOCATIONS_SAVED: {
    ...commonFields,
    category: 'VIP',
    action: 'home locations for VIP saved',
  },
  UNSUBSCRIBE_MODAL_OPENED: {
    ...commonFields,
    category: 'VIP',
    action: 'subscription cancel modal opened',
  },
  UNSUBSCRIBE_MODAL_CLOSED: {
    ...commonFields,
    category: 'VIP',
    action: 'unsubscribe modal closed',
  },
  JOIN_SUBSCRIPTION_BUTTON_CLICKED: {
    ...commonFields,
    category: 'VIP',
    action: 'join subscription button clicked',
  },
  SUBSCRIPTION_CANCEL_SUCCESS: {
    ...commonFields,
    category: 'VIP',
    action: 'Request to cancel subscription sent successfully',
  },
  SUBSCRIPTION_CANCEL_ERROR: {
    ...commonFields,
    category: 'VIP',
    action: 'Request to cancel subscription error',
  },
  SUBSCRIPTION_CANCEL_NO_AUTH: {
    ...commonFields,
    category: 'VIP',
    action: 'Not auth to this operation',
  },
  FREE_FLIGHT_OFFER_CLICKED: {
    ...commonFields,
    category: 'VIP',
    action: 'free flight offer clicked',
  },
};
