import { defaultTheme as defaultTokens } from '@kiwicom/orbit-components';
import { QUERIES } from '@kiwicom/orbit-components/lib/utils/mediaQuery';

export type MediaObject<T> = Readonly<{
  _?: T | null;
  largeDesktop?: T | null;
  desktop?: T | null;
  tablet?: T | null;
  largeMobile?: T | null;
  mediumMobile?: T | null;
}>;
type Borders = {
  listSeparator: string;
  none: string;
};
const { orbit } = defaultTokens;
const breakpoints = [
  0,
  orbit.widthBreakpointMediumMobile,
  orbit.widthBreakpointLargeMobile,
  orbit.widthBreakpointTablet,
  orbit.widthBreakpointDesktop,
  orbit.widthBreakpointLargeDesktop,
].map((i) => `${i}px`);

// This will allow us to use objectSyntax with styled-system media-queries
// eslint-disable-next-line dot-notation
breakpoints['smallMobile'] = breakpoints[0];
breakpoints[QUERIES.MEDIUMMOBILE] = breakpoints[1];
breakpoints[QUERIES.LARGEMOBILE] = breakpoints[2];
breakpoints[QUERIES.TABLET] = breakpoints[3];
breakpoints[QUERIES.DESKTOP] = breakpoints[4];
breakpoints[QUERIES.LARGEDESKTOP] = breakpoints[5];
const space = {
  '50': orbit.space50,
  '100': orbit.space100,
  '150': orbit.space150,
  '200': orbit.space200,
  '300': orbit.space300,
  '400': orbit.space400,
  '500': orbit.space500,
  '600': orbit.space600,
  '800': orbit.space800,
  '1000': orbit.space1000,
  // spaceXXXLarge got deprecated, we used new token
  // space1200 - 48px, instead 52px as it was before
  '1200': orbit.space1200,
  '1600': orbit.space1600,
  separatorSpacer: '1px 0 -1px 0',
};
// We might need to take whitelabels into consideration here, for now, usage of palette should be fine
// So when we need to expand this, we would probably make this a function, and return
// Values passed from _app, since some colors like primary and secondary will be different for WL's
const colors = {
  paletteWhite: orbit.paletteWhite,
  backgroundBody: orbit.backgroundBody,
  paletteCloudLight: orbit.paletteCloudLight,
  paletteCloudDark: orbit.paletteCloudDark,
};
const shadows = {
  boxShadowAction: orbit.boxShadowAction,
  boxShadowActionActive: orbit.boxShadowActionActive,
  boxShadowFixed: orbit.boxShadowFixed,
  none: 'none',
};
const radii = {
  borderRadiusFull: orbit.borderRadiusFull,
  borderRadius100: orbit.borderRadius100,
  borderRadius150: orbit.borderRadius150,
  borderRadius50: orbit.borderRadius50,
  borderRadiusBadge: orbit.borderRadiusBadge,
};
const zIndices = {
  zIndexDefault: orbit.zIndexDefault,
  zIndexSticky: orbit.zIndexSticky,
  zIndexModalOverlay: orbit.zIndexModalOverlay,
  zIndexModal: orbit.zIndexModal,
  zIndexOnTheTop: orbit.zIndexOnTheTop,
};

const getBorders = (theme) => ({
  listSeparator: `1px solid ${theme.orbit.backgroundSeparator}`,
  none: 'none',
});

type Theme = Readonly<{
  breakpoints: typeof breakpoints;
  space: typeof space;
  colors: typeof colors;
  shadows: typeof shadows;
  radii: typeof radii;
  zIndices: typeof zIndices;
  borders: Borders;
}>;
export type OrMediaObject<T> = T | MediaObject<T>;
export type Spacing = OrMediaObject<keyof typeof space | 0>;
export type Colors = OrMediaObject<keyof typeof colors>;
export type Shadows = OrMediaObject<keyof typeof shadows>;
export type BorderRadius = OrMediaObject<keyof typeof radii | 0> | string;
export type ZIndex = OrMediaObject<keyof typeof zIndices> | number;
export type Border = OrMediaObject<keyof Borders>;
export default function getStyles(theme: typeof defaultTokens): Theme {
  return {
    breakpoints,
    space,
    colors,
    shadows,
    radii,
    zIndices,
    borders: getBorders(theme),
  };
}
