import type { EventList } from '../../types';
import { commonFields } from '../../utils/defaultValues';

export const homepageGeneral: EventList = {
  PAGE_LOADED: { ...commonFields, category: 'homepage', action: 'loaded' },
  START_EXPLORING: { ...commonFields, category: 'homepage', action: 'clicked redirect to search' },
  TAB_LOADED: { ...commonFields, category: 'homepage', action: 'home page tab loaded' },
};
export const homepageMyTrips: EventList = {
  VISIT_BOOKING: { ...commonFields, category: 'homepage', action: 'visit booking' },
  REDIRECT_TO_TRAVEL_COMPANION: {
    ...commonFields,
    category: 'homepage',
    action: 'redirect to travel companion',
  },
  // Goes to mmb via the Refund deeplink
  VISIT_BOOKING_UPCOMING_REFUND_LINK: {
    ...commonFields,
    category: 'homepage',
    action: 'visit booking upcoming refund link',
  },
  // Fired when switching between Booking views (grid, filter, search)
  BOOKING_SCREEN_CLICKED: {
    ...commonFields,
    category: 'homepage',
    action: 'booking screen clicked',
  },
  SHOW_MORE_FUTURE: { ...commonFields, category: 'homepage', action: 'show more future' },
  SHOW_MORE_PAST: { ...commonFields, category: 'homepage', action: 'show more past' },
  VISIT_BOOKING_PAST: { ...commonFields, category: 'homepage', action: 'visit booking past' },
  // Goes to mmb via the Refund deeplink
  VISIT_BOOKING_PAST_REFUND_LINK: {
    ...commonFields,
    category: 'homepage',
    action: 'visit booking past refund link',
  },
  MY_TRIPS_TAB_LOADED: { ...commonFields, category: 'homepage', action: 'my trips tap loaded' },
  OPEN_SHARED_TRIP_MODAL: {
    ...commonFields,
    category: 'homepage',
    action: 'open shared trip modal',
  },
};
export const homepageFilterView: EventList = {
  FILTERVIEW_CLEAR: { ...commonFields, category: 'homepage', action: 'filterview clear filter' },
  FILTERVIEW_APPLY: { ...commonFields, category: 'homepage', action: 'filterview apply filter' },
  FILTERVIEW_VISIT_BOOKING: {
    ...commonFields,
    category: 'homepage',
    action: 'filterview visit booking',
  },
  FILTERVIEW_SHOW_MORE: { ...commonFields, category: 'homepage', action: 'filterview show more' },
};
export const homepageSearchView: EventList = {
  SEARCHVIEW_SEARCH: { ...commonFields, category: 'homepage', action: 'searchview search' },
  SEARCHVIEW_VISIT_BOOKING: {
    ...commonFields,
    category: 'homepage',
    action: 'searchview visit booking',
  },
  SEARCHVIEW_SHOW_MORE: { ...commonFields, category: 'homepage', action: 'searchview show more' },
};

export const travelCompanionBanner: EventList = {
  REDIRECT_TO_APP: {
    ...commonFields,
    category: 'homepage',
    action: 'redirect to app from travel companion banner',
  },
};

export const homepage = {
  ...homepageGeneral,
  ...homepageSearchView,
  ...homepageFilterView,
  ...homepageMyTrips,
  ...travelCompanionBanner,
};
