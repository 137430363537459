import type { OperationLoader } from 'relay-runtime';
import { RecordSource, Store } from 'relay-runtime';
import type { GetDataID } from 'relay-runtime/lib/store/RelayResponseNormalizer';
import type { RecordMap, Scheduler } from 'relay-runtime/lib/store/RelayStoreTypes';

type Options = {
  gcScheduler?: Scheduler | null | undefined;
  operationLoader?: OperationLoader | null | undefined;
  getDataID?: GetDataID | null | undefined;
  gcReleaseBufferSize?: number | null | undefined;
  queryCacheExpirationTime?: number | null | undefined;
  shouldProcessClientComponents?: boolean | null | undefined;
};
export default function createRelayStore(
  records: RecordMap | null | undefined,
  options?: Options,
): Store {
  const source = records == null ? new RecordSource() : new RecordSource(records);
  return new Store(source, options);
}
