import type { $Values } from 'utility-types';

export const CREDIT_TABS_NAMES = Object.freeze({
  AVAILABLE_AMOUNTS: 'available-amounts',
  TRANSACTIONS: 'transactions',
});

export const HOMEPAGE_TABS_NAMES = Object.freeze({
  TRIPS: 'trips',
  FARE_LOCKS: 'locked-prices',
  PRICE_ALERTS: 'price-alerts',
  CREDIT: 'credit',
  PREFERENCES: 'preferences',
  REFER_FRIEND: 'refer-friend',
  VIP_PROGRAM: 'vip-program',
  INBOX: 'inbox',
  WALLET: 'wallet',
});

export const INBOX_TABS_NAMES = Object.freeze({
  SUPPORT: 'support',
  NOTIFICATIONS: 'notifications',
});

export const SETTINGS_TABS_NAMES = Object.freeze({
  TRAVELERS: 'travelers',
  SETTINGS: 'settings',
  PRIVACY_SECURITY: 'privacy-security',
  FLIGHT_PREFERENCES: 'flight_preferences',
  PAYMENT: 'payment',
  BILLING: 'billing',
});

export const WALLET_TABS_NAMES = Object.freeze({
  PROMO_CODES: 'promo_codes',
  CREDITS: 'credits',
});

export type InboxTabsType = $Values<typeof INBOX_TABS_NAMES>;
export type HomePageTabsType = $Values<typeof HOMEPAGE_TABS_NAMES>;
export type SettingsTabsType = $Values<typeof SETTINGS_TABS_NAMES>;
