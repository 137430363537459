import { COOKIES, URLS, APP_ID, CITY_NAME_FORMATS } from './general';
import { DEEPLINKS, MOBILE_APP_DEEPLINKS } from './deeplinks';
import {
  CREDIT_TABS_NAMES,
  HOMEPAGE_TABS_NAMES,
  INBOX_TABS_NAMES,
  SETTINGS_TABS_NAMES,
  WALLET_TABS_NAMES,
} from './tabNames';
import { PROMO_AREA_CONTENT } from './promoArea';
import { INVITE_TRAVELER_SCENARIO, INVITE_TRAVELER_SCENARIOS } from './inviteTraveler';
import { PASSWORD_MIN_LENGTH, PASSWORD_MIN_SCORE } from './validation';
import type { HomePageTabsType, InboxTabsType, SettingsTabsType } from './tabNames';
import type { CITY_NAME_FORMATS_TYPE, TranslationType } from './general';

export type {
  HomePageTabsType,
  InboxTabsType,
  SettingsTabsType,
  CITY_NAME_FORMATS_TYPE,
  TranslationType,
};

const ACCOUNT_CONSTS = {
  COOKIES,
  URLS,
  APP_ID,
  DEEPLINKS,
  MOBILE_APP_DEEPLINKS,
  CITY_NAME_FORMATS,
  CREDIT_TABS_NAMES,
  HOMEPAGE_TABS_NAMES,
  WALLET_TABS_NAMES,
  INBOX_TABS_NAMES,
  SETTINGS_TABS_NAMES,
  PROMO_AREA_CONTENT,
  INVITE_TRAVELER_SCENARIO,
  INVITE_TRAVELER_SCENARIOS,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MIN_SCORE,
};

export default ACCOUNT_CONSTS;
