import type { MessageFormatElement } from 'react-intl';

import { LangInfos } from './types/LangInfos';

type IntlsICU = Record<
  string,
  { locale: string; messages: Record<string, MessageFormatElement[]> }
>;

const loadIntls = async () => {
  if (typeof window === 'undefined') {
    const fs = await import('fs');
    const path = await import('path');
    const getJSON = (filename: string) =>
      JSON.parse(fs.readFileSync(path.resolve(process.cwd(), './data/', filename), 'utf8'));

    const languages: LangInfos = await getJSON('languages.v2.json');

    const intlsICU: IntlsICU = Object.fromEntries(
      Object.values(languages).map(({ id, phraseApp }) => [
        id,
        {
          locale: id,
          messages: getJSON(`locales/${phraseApp}.json`),
        },
      ]),
    );
    return intlsICU;
  }
  console.warn('This file should be used on server and it is not intended to use in browser');
  return null;
};

export default loadIntls;
