import { commonFields } from '../../utils/defaultValues';
import type { EventList } from '../../types';

export const resetPassword: EventList = {
  RESET_PASSWORD_ERROR: {
    ...commonFields,
    category: 'resetPassword',
    action: 'Reset password error',
  },
  RESET_PASSWORD_SUCCESS: {
    ...commonFields,
    category: 'resetPassword',
    action: 'Reset password success',
  },
  RESET_PASSWORD_LOADED: {
    ...commonFields,
    category: 'resetPassword',
    action: 'Reset password page loaded',
  },
};
