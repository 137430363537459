import { commonFields } from '../../utils/defaultValues';
import type { EventList } from '../../types';

export const setPassword: EventList = {
  SET_PASSWORD_ERROR: { ...commonFields, category: 'setPassword', action: 'Set password error' },
  SET_PASSWORD_SUCCESS: {
    ...commonFields,
    category: 'setPassword',
    action: 'Set password success',
  },
  SET_PASSWORD_LOADED: {
    ...commonFields,
    category: 'setPassword',
    action: 'Set password page loaded',
  },
  SET_PASSWORD_REDIRECT: {
    ...commonFields,
    category: 'setPassword',
    action: 'Set password redirect to homepage',
  },
};
