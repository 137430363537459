import { createMedia } from '@artsy/fresnel';
import type { MediaContextProviderProps as FresnelMediaContextProviderProps } from '@artsy/fresnel/dist/Media';
import { defaultTheme as defaultTokens } from '@kiwicom/orbit-components';

const breakpoints = {
  smallMobile: 0,
  mediumMobile: defaultTokens.orbit.widthBreakpointMediumMobile,
  largeMobile: defaultTokens.orbit.widthBreakpointLargeMobile,
  tablet: defaultTokens.orbit.widthBreakpointTablet,
  desktop: defaultTokens.orbit.widthBreakpointDesktop,
  largeDesktop: defaultTokens.orbit.widthBreakpointLargeDesktop,
};

const AppMedia = createMedia({
  breakpoints,
});

export type MediaContextProviderProps = FresnelMediaContextProviderProps<keyof typeof breakpoints>;

export const { createMediaStyle } = AppMedia;
export const { Media, MediaContextProvider } = AppMedia;
