export const DEEPLINKS = Object.freeze({
  NOTIFICATION_SETTINGS: 'notification-settings',
  ADD_TRAVELER: 'add-traveler',
  REGIONAL_SETTINGS: 'regional-settings',
  EDIT_ACCOUNT_OWNER: 'edit-account-owner',
  SHARE_INVITE_LINK: 'share-invite-link',
  OPEN_SHARE_MODAL: 'open-share-modal',
});

export const MOBILE_APP_DEEPLINKS = Object.freeze({
  PROMOTION_AREA_APP_STORE:
    'https://app.kiwi.com/?link=https%3A%2F%2Fkiwi.com%2Fmobile%3Futm_campaign%3Dpromotion_area%26utm_medium%3Daccount%26utm_source%3Dproduct%26utm_content%3Dios&apn=com.skypicker.main&isi=657843853&ibi=com.skypicker.Skypicker&utm_campaign=promotion_area&utm_medium=account&utm_source=product&utm_content=ios&ofl=https%3A%2F%2Fitunes.apple.com%2Fus%2Fapp%2Fid657843853%3Fmt%3D8',
  PROMOTION_AREA_GOOGLE_PLAY:
    'https://app.kiwi.com/?link=https%3A%2F%2Fkiwi.com%2Fmobile%3Futm_campaign%3Dpromotion_area%26utm_medium%3Daccount%26utm_source%3Dproduct%26utm_content%3Dandroid&apn=com.skypicker.main&utm_campaign=promotion_area&utm_medium=account&utm_source=product&utm_content=android&ofl=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.skypicker.main%26utm_source%3Dkiwi.com',
  PROMOTION_AREA_GENERIC:
    'https://app.kiwi.com/?link=https%3A%2F%2Fkiwi.com%2Fmobile%3Futm_campaign%3Dpromotion_area%26utm_medium%3Daccount%26utm_source%3Dproduct&apn=com.skypicker.main&isi=657843853&ibi=com.skypicker.Skypicker&utm_campaign=promotion_area&utm_medium=account&utm_source=product',
  ONBOARDING_APP_STORE:
    'https://app.kiwi.com/?link=https://kiwi.com/mobile?utm_campaign%3D+account_onboarding%26utm_medium%3Daccount%26utm_source%3Dproduct&apn=com.skypicker.main&isi=657843853&ibi=com.skypicker.Skypicker&utm_campaign=account_onboarding&utm_medium=account&utm_source=product&ofl=https%3A%2F%2Fitunes.apple.com%2Fus%2Fapp%2Fid657843853%3Fmt%3D8',
  ONBOARDING_GOOGLE_PLAY:
    'https://app.kiwi.com/?link=https://kiwi.com/mobile?utm_campaign%3D+account_onboarding%26utm_medium%3Daccount%26utm_source%3Dproduct&apn=com.skypicker.main&isi=657843853&ibi=com.skypicker.Skypicker&utm_campaign=account_onboarding&utm_medium=account&utm_source=product&ofl=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.skypicker.main%26utm_source%3Dkiwi.com',
  REFER_FRIEND_GENERIC:
    'https://app.kiwi.com/?link=https%3A%2F%2Fkiwi.com%2Fmobile%3Futm_campaign%3Drefer_friend%26utm_medium%3Daccount%26utm_source%3Dproduct&apn=com.skypicker.main&isi=657843853&ibi=com.skypicker.Skypicker&utm_campaign=refer_friend&utm_medium=account&utm_source=product',
  REFER_FRIEND_APP_STORE:
    'https://app.kiwi.com/?link=https%3A%2F%2Fkiwi.com%2Fmobile%3Futm_campaign%3Drefer_friend%26utm_medium%3Daccount%26utm_source%3Dproduct%26utm_content%3Dios&isi=657843853&ibi=com.skypicker.Skypicker&utm_campaign=refer_friend&utm_medium=account&utm_source=product&utm_content=ios',
  REFER_FRIEND_GOOGLE_PLAY:
    'https://app.kiwi.com/?link=https%3A%2F%2Fkiwi.com%2Fmobile%3Futm_campaign%3Drefer_friend%26utm_medium%3Daccount%26utm_source%3Dproduct%26utm_content%3Dandroid&apn=com.skypicker.main&utm_campaign=refer_friend&utm_medium=account&utm_source=product&utm_content=android',
  TRAVEL_COMPANION_REDIRECT:
    'https://app.kiwi.com/?link=https://kiwi.com/mobile?utm_source%3Dproduct%26utm_medium%3Daccount%26utm_capaing%3Dmy_trips_travel_companion%26utm_content%3Dlive_updates_upcoming_trip_responsive&apn=com.skypicker.main&isi=657843853&ibi=com.skypicker.Skypicker&utm_campaign=my_trips_travel_companion&utm_medium=account&utm_source=product&utm_content=live_updates_upcoming_trip_responsive',
  TRAVEL_COMPANION_BANNER:
    'https://app.kiwi.com/?link=https://kiwi.com/mobile?utm_source%3Dproduct%26utm_medium%3Daccount%26utm_capaing%3Dmy_trips_travel_companion%26utm_content%3Dlive_updates_upcoming_trip_qr_code&apn=com.skypicker.main&isi=657843853&ibi=com.skypicker.Skypicker&utm_campaign=my_trips_travel_companion%26utm_content%3Dlive_updates_upcoming_trip_qr_code&utm_medium=account&utm_source=product&utm_content=live_updates_upcoming_trip_qr_code',
});
