import type { ReactNode } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { mediaQueries as media } from '@kiwicom/account-global-contexts';

type GlobalStyleProps = {
  backgroundColor: string;
  fontFamily: string | null | undefined;
};

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
    *,
    *::after,
    *::before {
      margin: 0;
      padding: 0;
      box-sizing: inherit;
    }
    body {
      background-color: ${({ backgroundColor, theme }) =>
        // @ts-ignore: Property 'orbit' does not exist on type 'DefaultTheme'
        backgroundColor ?? theme.orbit.backgroundBody};
      box-sizing: border-box;
      font-family: ${({ fontFamily }) => fontFamily};
    }
    #modals {
      position: relative;
      z-index: 800;
    }
    /* This will work as a visual eslint if you add headers in the wrong order */
    h2 ~ h1,
    h3 ~ h1,
    h4 ~ h1,
    h5 ~ h1,
    h6 ~ h1,
    h3 ~ h2:first-of-type,
    h4 ~ h2:first-of-type,
    h5 ~ h2:first-of-type,
    h6 ~ h2:first-of-type,
    h4 ~ h3:first-of-type,
    h5 ~ h3:first-of-type,
    h6 ~ h3:first-of-type,
    h5 ~ h4:first-of-type,
    h6 ~ h4:first-of-type,
    h6 ~ h5:first-of-type {
      border: 2px dotted blue;
    }
  `;

type GlobalLayoutContainerProps = {
  isWebview: boolean;
  children: ReactNode;
};

export const GlobalLayoutContainer = styled.div<GlobalLayoutContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${({ isWebview }) => !isWebview && '52px'};
  ${media.tablet(css<GlobalLayoutContainerProps>`
    margin-top: ${({ isWebview }) => !isWebview && '64px'};
  `)};
`;
