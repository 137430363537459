import { commonFields } from '../../utils/defaultValues';
import type { EventList } from '../../types';

export const savedTravellers: EventList = {
  LOADED: { ...commonFields, category: 'savedTravellers', action: 'loaded' },
  ADD_NEW_CLICK: { ...commonFields, category: 'savedTravellers', action: 'click add new' },
  ADD_NEW_CLOSE: {
    ...commonFields,
    category: 'savedTravellers',
    action: 'click add new close button',
  },
  ADD_NEW_SAVE: {
    ...commonFields,
    category: 'savedTravellers',
    action: 'click add new save button',
  },
  SUCCESSFULLY_ADDED: {
    ...commonFields,
    category: 'savedTravellers',
    action: 'new passenger successfully added',
  },
  INFO_EDITED: { ...commonFields, category: 'savedTravellers', action: 'passenger info edited' },
  ERROR_EDITING: {
    ...commonFields,
    category: 'savedTravellers',
    action: 'error editing passenger',
  },
  DELETE_TRAVELER_CLICKED: {
    ...commonFields,
    category: 'savedTravellers',
    action: 'passenger delete clicked',
  },
  DELETE_TRAVELER: { ...commonFields, category: 'savedTravellers', action: 'passenger deleted' },
  ERROR_ADDING: {
    ...commonFields,
    category: 'savedTravellers',
    action: 'error adding new passenger',
  },
  ADD_TRAVELER_MANUALLY: {
    ...commonFields,
    category: 'savedTravellers',
    action: 'click on add traveler manually',
  },
};
