import { commonFields } from '../../utils/defaultValues';
import type { EventList } from '../../types';

export const notificationsSettings: EventList = {
  NOTIFICATION_SETTINGS_PAGE: {
    ...commonFields,
    category: 'notificationsSettings',
    action: 'Loaded',
  },
  OPEN_NOTIFICATIONS_SETTINGS_MODAL: {
    ...commonFields,
    category: 'notificationsSettings',
    action: 'Open notifications settings modal',
  },
  CLOSE_NOTIFICATIONS_SETTINGS_MODAL: {
    ...commonFields,
    category: 'notificationsSettings',
    action: 'Close notifications settings modal',
  },
  CHANGE_NOTIFICATIONS_SETTINGS_SUCCESS: {
    ...commonFields,
    category: 'notificationsSettings',
    action: 'Changed notifications settings successfully',
  },
  CHANGE_NOTIFICATIONS_SETTINGS_ERROR: {
    ...commonFields,
    category: 'notificationsSettings',
    action: 'Change notifications settings error',
  },
};
