import { commonFields } from '../../utils/defaultValues';
import type { EventList } from '../../types';

export const billingAddress: EventList = {
  OPEN_ADD_BILLING_ADDRESS_MODAL: {
    ...commonFields,
    category: 'billingAddress',
    action: 'opened add billing address modal',
  },
  CLOSE_ADD_BILLING_ADDRESS_MODAL: {
    ...commonFields,
    category: 'billingAddress',
    action: 'closed add billing address modal',
  },
  ADD_BILLING_ADDRESS_SUCCESS: {
    ...commonFields,
    category: 'billingAddress',
    action: 'added billing address successfully',
  },
  ADD_BILLING_ADDRESS_ERROR: {
    ...commonFields,
    category: 'billingAddress',
    action: 'error happened when adding a billing address',
  },
  OPEN_EDIT_BILLING_ADDRESS_MODAL: {
    ...commonFields,
    category: 'billingAddress',
    action: 'opened edit billing address modal',
  },
  CLOSE_EDIT_BILLING_ADDRESS_MODAL: {
    ...commonFields,
    category: 'billingAddress',
    action: 'closed edit billing address modal',
  },
  EDIT_BILLING_ADDRESS_SUCCESS: {
    ...commonFields,
    category: 'billingAddress',
    action: 'edited billing address successfully',
  },
  EDIT_BILLING_ADDRESS_ERROR: {
    ...commonFields,
    category: 'billingAddress',
    action: 'error happened when editing a billing address',
  },
  OPEN_DELETE_BILLING_ADDRESS_MODAL: {
    ...commonFields,
    category: 'billingAddress',
    action: 'opened delete billing address modal',
  },
  CLOSE_DELETE_BILLING_ADDRESS_MODAL: {
    ...commonFields,
    category: 'billingAddress',
    action: 'closed delete billing address modal',
  },
  DELETE_BILLING_ADDRESS_SUCCESS: {
    ...commonFields,
    category: 'billingAddress',
    action: 'deleted billing address successfully',
  },
  DELETE_BILLING_ADDRESS_ERROR: {
    ...commonFields,
    category: 'billingAddress',
    action: 'error happened when deleting a billing address',
  },
};
