export const defaultDestinations = {
  logmole: true,
  exponea: false,
  ga: true,
  bigQuery: true,
  datadog: true,
};

export const commonFields = {
  destinations: defaultDestinations,
};
