import { commonFields } from '../../utils/defaultValues';
import type { EventList } from '../../types';

export const paymentMethods: EventList = {
  PAYMENT_METHODS_LOADED: {
    ...commonFields,
    category: 'paymentMethods',
    action: 'payment methods loaded',
  },
  ADD_CREDIT_CARD: {
    ...commonFields,
    category: 'paymentMethods',
    action: 'successfully added credit card',
  },
  ADD_CREDIT_CARD_ERROR: { ...commonFields, category: 'paymentMethods', action: 'error' },
  REMOVE_CREDIT_CARD: { ...commonFields, category: 'paymentMethods', action: 'remove credit card' },
  OPEN_ADD_CREDIT_CARD_MODAL: {
    ...commonFields,
    category: 'paymentMethods',
    action: 'open add credit card modal',
  },
  MAKE_DEFAULT: { ...commonFields, category: 'paymentMethods', action: 'make credit card default' },
};
