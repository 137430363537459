import { css } from 'styled-components';
import type { Interpolation } from 'styled-components';
import type { Theme } from '@kiwicom/orbit-components';

export type Devices =
  | 'largeDesktop'
  | 'desktop'
  | 'tablet'
  | 'largeMobile'
  | 'mediumMobile'
  | 'smallMobile';

export enum QUERIES {
  MEDIUMMOBILE = 'mediumMobile',
  LARGEMOBILE = 'largeMobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
  LARGEDESKTOP = 'largeDesktop',
}

export const TOKEN = {
  mediumMobile: 'widthBreakpointMediumMobile',
  largeMobile: 'widthBreakpointLargeMobile',
  tablet: 'widthBreakpointTablet',
  desktop: 'widthBreakpointDesktop',
  largeDesktop: 'widthBreakpointLargeDesktop',
} as const;

export type BreakpointToken =
  | 'widthBreakpointMediumMobile'
  | 'widthBreakpointLargeMobile'
  | 'widthBreakpointTablet'
  | 'widthBreakpointDesktop'
  | 'widthBreakpointLargeDesktop';

//eslint-disable-next-line
export type QueryFunction = (style: Interpolation<any>) => Interpolation<any>;

export type MediaQueries = Record<keyof typeof TOKEN, QueryFunction>;

const getBreakpointWidth = (name: keyof typeof TOKEN, theme: Theme, pure?: boolean) => {
  return pure ? theme.orbit[TOKEN[name]] : `(min-width: ${theme.orbit[TOKEN[name]]}px)`;
};

export const mediaQueries = Object.values(QUERIES).reduce<MediaQueries>(
  (acc: MediaQueries, device: keyof typeof TOKEN) => {
    acc[device] = (style) => css`
      @media ${({ theme }) => getBreakpointWidth(device, theme)} {
        ${style};
      }
    `;

    return acc;
  },
  //eslint-disable-next-line
  { mediumMobile: {}, largeMobile: {}, tablet: {}, desktop: {}, largeDesktop: {} } as MediaQueries,
);
