import type { EventList } from '../../types';
import { commonFields } from '../../utils/defaultValues';

export const inviteTraveler: EventList = {
  COPY_INVITATION_LINK: {
    ...commonFields,
    category: 'inviteTraveler',
    action: 'Clicked on copy invitation link',
  },
  COPY_SPECIFIC_INVITATION_LINK: {
    ...commonFields,
    category: 'inviteTraveler',
    action: 'Clicked on copy specific invitation link',
    description:
      'This event is triggered when the user copies the invite link in for a specific traveler',
  },
  ACCOUNTS_LINKED: {
    ...commonFields,
    category: 'inviteTraveler',
    action: 'Invite traveler flow successful, accounts linked',
  },
  INTRO_SCREEN_SHOWN: {
    ...commonFields,
    category: 'inviteTraveler',
    action: 'Intro screen loaded',
    description: 'User landed on intro screen of invite traveler flow',
  },
  USER_SIGNED_IN: {
    ...commonFields,
    category: 'inviteTraveler',
    action: 'User signed in',
    description: 'User signed in at the intro screen',
  },
  REDIRECT_TO_SHARE_DETAILS: {
    ...commonFields,
    category: 'inviteTraveler',
    action: 'Redirected to share details',
  },
  SHARING_DETAILS_LOADED: {
    ...commonFields,
    category: 'inviteTraveler',
    action: 'Sharing details screen loaded',
  },
  OPEN_LOGIN_MODAL: {
    ...commonFields,
    category: 'inviteTraveler',
    action: 'Open login modal',
  },
  EDIT_DETAILS: {
    ...commonFields,
    category: 'inviteTraveler',
    action: 'User clicked on edit details',
  },
  NAVIGATOR_SHARE_ERROR: {
    ...commonFields,
    category: 'inviteTraveler',
    action: 'error when sharing link via mobile native navigator panel',
  },
};
