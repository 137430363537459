import { commonFields } from '../../utils/defaultValues';
import type { EventList } from '../../types';

export const inbox: EventList = {
  INBOX_TAB_LOADED: { ...commonFields, category: 'inbox', action: 'inbox tab loaded' },
  NOTIFICATIONS_TAB_LOADED: {
    ...commonFields,
    category: 'inbox',
    action: 'notifications tab loaded',
  },
  SUPPORT_TAB_LOADED: { ...commonFields, category: 'inbox', action: 'support tab loaded' },
};
