import { isBrowser } from '@adeira/js';
import type { UserLocation } from '@kiwicom/account-global-contexts';
import '@kiwicom/account-global-contexts';
import { captureException } from '@kiwicom/account-sentry';
import { format } from '@kiwicom/useragent';

const getHeaders = () => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (isBrowser() === false) {
    headers['User-Agent'] = format(
      'account-frontend',
      process.env.NEXT_PUBLIC_COMMIT_SHORT_SHA,
      process.env.APP_ENV,
    );
  }

  return headers;
};

export default async function fetchUserLocation(
  clientIp: string | null | undefined,
): Promise<UserLocation | null> {
  let userLocation = null;

  try {
    const locationJson = await fetch(`https://geoip-api.skypicker.com?ip=${clientIp ?? ''}`, {
      method: 'GET',
      headers: getHeaders(),
    });
    userLocation = await locationJson.json();
  } catch (error) {
    captureException(error as Error);
    return null;
  }

  return userLocation;
}
