import type { $Shape } from 'utility-types';
import type { Props, Event } from '@kiwicom/nitro/lib/records/Event';
import type { Globals } from '@kiwicom/nitro/lib/records/Loglady';
import { server as logServerEvent } from '@kiwicom/nitro/lib/services/log/logger';

const serverGlobals = {
  project: 'frontend',
  module: 'account',
};

const logServer = (event: Event, props: Props, globals?: $Shape<Globals>): Promise<void> => {
  return logServerEvent(event, props, { ...serverGlobals, ...(globals ?? {}) });
};

export default logServer;
