import { commonFields } from '../../utils/defaultValues';
import type { EventList } from '../../types';

export const referFriend: EventList = {
  VISIT_LANDING_PAGE: {
    ...commonFields,
    category: 'refer friend',
    action: 'landing page visited',
  },
  CLICK_CREATE_ACCOUNT: {
    ...commonFields,
    category: 'refer friend',
    action: 'create account button clicked',
  },
  CLICK_SIGN_IN: {
    ...commonFields,
    category: 'refer friend',
    action: 'sign in button clicked',
  },
  SUBMIT_INVITE_TOKEN: {
    ...commonFields,
    category: 'refer friend',
    action: 'submit invite token',
  },
  VISIT_SUCCESS_PAGE: {
    ...commonFields,
    category: 'refer friend',
    action: 'success page visited',
  },
  SHOWED_ERROR_SCREEN: {
    ...commonFields,
    category: 'refer friend',
    action: 'error screen showed',
    // pass errorType as prop when using event
  },
  VISIT_REFER_FRIEND: {
    ...commonFields,
    category: 'refer friend',
    action: 'screen displayed',
  },
  COPY_INVITE_LINK: {
    ...commonFields,
    category: 'refer friend',
    action: 'copy button clicked',
  },
  COPY_PROMO_CODE_VALUE: {
    ...commonFields,
    category: 'refer friend',
    action: 'copy promo code button clicked',
  },
  CLICK_SHARE_INVITE_LINK: {
    ...commonFields,
    category: 'refer friend',
    action: 'share button clicked',
  },
  CLICK_SHARE_ON_FB: {
    ...commonFields,
    category: 'refer friend',
    action: 'share on facebook button clicked',
  },
  CLICK_SHARE_ON_TWITTER: {
    ...commonFields,
    category: 'refer friend',
    action: 'share on twitter button clicked',
  },
  CLICK_FIND_TRIP: {
    ...commonFields,
    category: 'refer friend',
    action: 'find trip button clicked',
  },
  CLICK_OPEN_EMAIL: {
    ...commonFields,
    category: 'refer friend',
    action: 'open email button clicked',
  },
  NAVIGATOR_SHARE_CLOSED: {
    ...commonFields,
    category: 'refer friend',
    action: 'mobile navigator share closed',
  },
  CLICK_VIEW_REFERRALS_BUTTON: {
    ...commonFields,
    category: 'refer friend',
    action: 'View referrals button clicked',
  },
};
