import * as Sentry from '@sentry/node'; // in browser aliased to @sentry/browser (next.config.js)
import { Cookie, load as cookieLoad } from '@kiwicom/cookies';
// eslint-disable-next-line @next/next/no-document-import-in-page
import type { DocumentContext as Context } from 'next/document';
import { getDomainType } from '@kiwicom/account-client-utils';

type ErrorInfo = {
  readonly componentStack?: string;
  readonly req?: Context['req'];
};

export const captureException = (err: Error, errorInfo?: ErrorInfo) => {
  const sentryScope = Sentry.getCurrentScope();

  if (err.message) {
    // De-duplication currently doesn't work correctly for SSR / browser errors
    // so we force deduplication by error message if it is present
    sentryScope.setFingerprint([err.message]);
  }

  const { req } = errorInfo ?? {};

  if (req != null) {
    sentryScope.setExtra('url', req.url);
    sentryScope.setExtra('method', req.method);
    sentryScope.setExtra('headers', req.headers);
    sentryScope.setExtra('domainType', getDomainType(req.url ?? ''));
  }

  if (typeof window === 'undefined') {
    sentryScope.setTag('ssr', true);
  } else {
    sentryScope.setTag('ssr', false);
    const visitorId = cookieLoad(Cookie.USER_ID) ?? '';

    if (visitorId) {
      sentryScope.setUser({
        id: visitorId,
      });
    }
  }

  if (errorInfo?.componentStack != null) {
    sentryScope.setExtra('componentStack', errorInfo.componentStack);
  }
  Sentry.captureException(err);
};
export const captureMessage = (message: string, errorObj?: Record<string, unknown>) => {
  const sentryScope = Sentry.getCurrentScope();

  if (typeof window === 'undefined') {
    sentryScope.setTag('ssr', true);
  } else {
    sentryScope.setTag('ssr', false);
    const visitorId = cookieLoad(Cookie.USER_ID) ?? '';

    if (visitorId) {
      sentryScope.setUser({
        id: visitorId,
      });
    }
  }

  if (errorObj) {
    sentryScope.setExtra('errorObj', errorObj);
  }

  Sentry.captureMessage(message);
};
