import type { $Values } from 'utility-types';

export const URLS = {
  SKY_USERS_API: `https://auth.skypicker.com`,
  PAYMENT_IFRAME_PRODUCTION_URL: 'https://fe.payments-kiwi.com',
  PAYMENT_IFRAME_SANDBOX_URL: 'https://fe.payments-kiwi-dev.com',
};

export const COOKIES = {
  X_CYPRESS_USER: 'x-cypress-user',
  USE_CYPRESS_MOCK: 'use_cypress_mock',
  GQL_ENDPOINT: 'gql_endpoint',
  FILTER_VIEW: 'filter_view',
  USE_SANDBOX: 'use_sandbox',
  BILLING_ADDRESS_DATA: 'billing_address_data',
  INVITER_FIRST_NAME: 'inviter_first_name',
  REFERRER: 'referrer',
};

// BUSINESS
// used for authentication on skyusers in combination with the token
export const APP_ID = '5433ecccaff67';

// Used for formatting city names on the client side
export const CITY_NAME_FORMATS = {
  PRICE_ALERT_MODAL: 'PriceAlertModal',
  PRICE_ALERT_TITLE: 'PriceAlertTile',
  SEARCH_RESULT: 'SearchResult',
  DEAL_HEADER: 'DealHeader',
};

export type CITY_NAME_FORMATS_TYPE = $Values<typeof CITY_NAME_FORMATS>;

export type TranslationType = JSX.Element | string | null;
