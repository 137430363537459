import type { EventList } from '../../types';
import { commonFields } from '../../utils/defaultValues';

export const bookingSharing: EventList = {
  OPEN_SHARING_MODAL: {
    ...commonFields,
    category: 'bookingSharing',
    action: 'opened sharing modal',
  },
  CLOSE_SHARING_MODAL: {
    ...commonFields,
    category: 'bookingSharing',
    action: 'closed sharing modal',
  },
  SHARING_MODAL_NO_RESULTS: {
    ...commonFields,
    category: 'bookingSharing',
    action: 'there is no trip to share',
  },
  OPEN_FACEBOOK_SHARE_WINDOW: {
    ...commonFields,
    category: 'bookingSharing',
    action: 'opened facebook share window',
  },
  OPEN_TWITTER_SHARE_WINDOW: {
    ...commonFields,
    category: 'bookingSharing',
    action: 'opened twitter share window',
  },
  LINK_COPIED: { ...commonFields, category: 'bookingSharing', action: 'link copied' },
  OPEN_BOARDING_PASSES: {
    ...commonFields,
    category: 'homepage',
    action: 'open boarding passes link from upcoming trip',
  },
  DOWNLOAD_BOARDING_PASSES: {
    ...commonFields,
    category: 'homepage',
    action: 'download boarding passes from upcoming trip modal',
  },
};
