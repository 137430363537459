import type { EventList } from '../../types';
import { commonFields } from '../../utils/defaultValues';

export const ancillary: EventList = {
  // Fires when clicking on next trip ancillary
  ANCILLARY_CTA_CLICKED: {
    ...commonFields,
    category: 'ancillary',
    action: 'clicked on ancillary cta link',
  },
  VIEW_ALL_SERVICES: {
    ...commonFields,
    category: 'ancillary',
    action: 'view all services clicked',
  },
};
