import { commonFields } from '../../utils/defaultValues';
import type { EventList } from '../../types';

export const magicLogin: EventList = {
  EXPIRED_MAGIC_TOKEN: {
    ...commonFields,
    category: 'magicLogin',
    action: 'Magic token has expired',
  },
  REUSED_MAGIC_TOKEN: {
    ...commonFields,
    category: 'magicLogin',
    action: 'Magic token has been used already',
  },
  MAGIC_TOKEN_GENERAL_ERROR: {
    ...commonFields,
    category: 'magicLogin',
    action: 'Magic token general error',
  },
  MAGIC_TOKEN_EXCHANGED: {
    ...commonFields,
    category: 'magicLogin',
    action: 'Magic token exchanged',
  },
  ACCOUNT_GRAPHQl_ERROR: {
    ...commonFields,
    category: 'magicLogin',
    action: 'Account graphql error',
  },
  REDIRECT_TO_HOMEPAGE: { ...commonFields, category: 'magicLogin', action: 'Redirect to homepage' },
  REDIRECT_TO_SET_PASSWORD: {
    ...commonFields,
    category: 'magicLogin',
    action: 'Redirect to set password page',
  },
};
