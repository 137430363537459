import { commonFields } from '../../utils/defaultValues';
import type { EventList } from '../../types';

export const wallet: EventList = {
  WALLET_LOADED: { ...commonFields, category: 'wallet', action: ' loaded' },
  WALLET_REDIRECT: { ...commonFields, category: 'wallet', action: 'redirect from credits' },
  KIWICREDIT_CLICK_BID: { ...commonFields, category: 'wallet', action: 'kiwicredit click bid' },
  KIWICREDIT_SHOW_MORE: { ...commonFields, category: 'wallet', action: 'show more credits' },
  PROMO_CODES_SHOW_MORE: { ...commonFields, category: 'wallet', action: 'show more promo codes' },
  COPY_PROMO_CODE: {
    ...commonFields,
    category: 'wallet',
    action: 'copy promo code button clicked',
  },
  COPY_INVITE_LINK: {
    ...commonFields,
    category: 'wallet',
    action: 'copy invite link button clicked',
  },
};
