import type { EventList } from '../../types';
import { commonFields } from '../../utils/defaultValues';

export const flightPreferences: EventList = {
  FLIGHT_ANCILLARIES_PREFERENCES_PAGE_LOADED: {
    ...commonFields,
    category: 'flight preferences',
    action: 'loaded',
  },
  SEATING_PREFERENCES_EDIT_BUTTON_CLICKED: {
    ...commonFields,
    category: 'flight preferences',
    action: 'seating preferences edit button clicked',
  },
  SEATING_PREFERENCES_SAVE_BUTTON_CLICKED: {
    ...commonFields,
    category: 'flight preferences',
    action: 'seating preferences save button clicked',
  },
  BAGGAGE_PREFERENCES_EDIT_BUTTON_CLICKED: {
    ...commonFields,
    category: 'flight preferences',
    action: 'baggage preferences edit button clicked',
  },
  BAGGAGE_PREFERENCES_SAVE_BUTTON_CLICKED: {
    ...commonFields,
    category: 'flight preferences',
    action: 'baggage preferences save button clicked',
  },
  BAGGAGE_PREFERENCES_TAB_CLICKED: {
    ...commonFields,
    category: 'flight preferences',
    action: 'baggage preferences tab clicked',
  },
};
