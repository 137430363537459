import { commonFields } from '../../utils/defaultValues';
import type { EventList } from '../../types';

export const sfaq: EventList = {
  LEARN_MORE_CLICKED: {
    ...commonFields,
    category: 'sfaq',
    action: 'learn more clicked',
  },
};
