import { commonFields } from '../../utils/defaultValues';
import type { EventList } from '../../types';

export const verifyEmail: EventList = {
  INVALID_TOKEN: { ...commonFields, category: 'verifyEmail', action: 'Invalid token' },
  GENERAL_ERROR: { ...commonFields, category: 'verifyEmail', action: 'General error' },
  ACCOUNT_GRAPHQl_ERROR: {
    ...commonFields,
    category: 'verifyEmail',
    action: 'Account graphql error',
  },
  SUCCESSFULL_VERIFICATION: {
    ...commonFields,
    category: 'verifyEmail',
    action: 'Successfull verification',
  },
  REDIRECT_TO_HOMEPAGE: {
    ...commonFields,
    category: 'verifyEmail',
    action: 'Redirect to homepage',
  },
};
