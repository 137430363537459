import type { Context } from 'react';
import { createContext, useMemo, useContext } from 'react';
import { invariant } from '@adeira/js';

type State = {
  readonly useLocalQueryRenderer: boolean;
  readonly fetchError: boolean;
};
export type Props = {
  readonly children: JSX.Element;
  readonly useLocalQueryRenderer: boolean;
  readonly fetchError: boolean;
};

const defaultContext = {
  useLocalQueryRenderer: false,
  fetchError: false,
};

const SSRContext: Context<State | null | undefined> = createContext<State | null | undefined>(
  defaultContext,
);

const SSRDataProvider = ({ children, useLocalQueryRenderer, fetchError }: Props): JSX.Element => {
  const state = useMemo(
    () => ({
      useLocalQueryRenderer,
      fetchError,
    }),
    [fetchError, useLocalQueryRenderer],
  );

  return <SSRContext.Provider value={state}>{children}</SSRContext.Provider>;
};

const useSSRData = (): State => {
  const context = useContext(SSRContext);

  invariant(context != null, 'No context found for SSRContext, did you forget the SSRProvider?');

  return context;
};

export { SSRDataProvider, useSSRData };
